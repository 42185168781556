import { Subscription } from "./subscription";

export class EventEmitter<E> {
  constructor() {}

  private readonly subscribtions: Subscription<E>[] = [];

  public subscribe<T>(event: E, callback: (data: T) => void): Subscription<E> {
    const subscription = new Subscription(event, callback, this.unsubscribe.bind(this, event, callback));
    this.subscribtions.push(subscription);

    return subscription;
  }

  public unsubscribe<T>(event: E, callback: (data: T) => void): void {
    this.subscribtions.filter(subscription => (subscription.event != event || subscription.callback != callback));
  }

  public emit<T>(event: E, data?: T): void {
    this.subscribtions.filter(subscription => subscription.event == event)
      .forEach(subscription => subscription.notify(data));
  }
}