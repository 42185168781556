import { AppConfig } from "../config/app.config";
import { PostMessageDto } from "../dto/post-message.dto";
import { QuizEvent } from "../enums/quiz-event.enum";
import { IIframe } from "../interfaces/iframe.interface";
import { IPostMessage } from "../interfaces/post-message.interface";
import { validateObject } from "../utils/validate-object";
import { EventEmitter } from "../components/event-emitter";

export class PostMessageProvider {
  constructor(
    private readonly iframe: IIframe,
    private readonly eventEmitter: EventEmitter<QuizEvent>,
    private readonly config: AppConfig
  ) { this.subscribe() }

  private subscribe() {
    window.addEventListener("message", ({data}) => {
      try {
        const message = JSON.parse(data) as IPostMessage;

        validateObject(new PostMessageDto(message));
        this.eventEmitter.emit(message.event, message.payload);
        
      } catch(error) { 
        if (this.config.env == "development") console.error(error);
      }
    });
  }

  public send(message: Pick<IPostMessage, "event" | "payload">) {
    const dto = {...message, access_key: this.config.post_message_access_key};
    try {
      validateObject(new PostMessageDto(dto));
      this.iframe.window.postMessage(JSON.stringify(dto), this.config.quiz_host);
    } catch (error) {
      if (this.config.env == "development") throw new Error(error);
    }
  }
}