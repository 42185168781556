import { IsEnum, IsOptional } from "class-validator";
import { MiniWidgetPosition } from "../enums/mini-widget-position.enum";
import { MiniWidgetViewOptions } from "../interfaces/widget-view-options.interface";

export class MiniWidgetViewOptionsDto implements MiniWidgetViewOptions {
  constructor(options?: MiniWidgetViewOptions) {
    if (options) {
      this.imageSrc = options.imageSrc;
      this.position = options.position;
      this.style = options.style;
      this.hoverStyle = options.hoverStyle;
    }
  }
  
  @IsOptional()
  readonly imageSrc?: string;

  @IsOptional()
  @IsEnum(MiniWidgetPosition)
  readonly position?: MiniWidgetPosition = MiniWidgetPosition.RIGHT_BOTTOM;

  @IsOptional()
  readonly style?: Partial<CSSStyleDeclaration>;

  @IsOptional()
  readonly hoverStyle?: Partial<CSSStyleDeclaration>;
}