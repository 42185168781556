export enum QuizEvent {
  INIT = "init",
  OPEN = "open",
  CLOSE = "close",
  CLICK_CROSS = "click_cross",
  CLOSE_WITH_ERROR = "close_with_error",
  LOAD = "load",
  GET_LEAD = "get_lead",
  SET_PLUGIN_DATA = "set_plugin_data",
  REACH_GOAL = "reach_goal",
  SET_PARAMS = "set_params",
  SET_AB_TEST = "set_ab_test"
}