import { QuizMode } from "../enums/quiz-mode.enum";
import device from 'current-device';
import styles from './iframe.module.scss';
import { IIframe } from "../interfaces/iframe.interface";

export class IFrame implements IIframe {
  
  constructor(
    private readonly host: string, 
    private readonly mode: QuizMode,
  ) {}

  private iframe: HTMLIFrameElement;
  private element: HTMLElement;
  private _window?: Readonly<Window> = undefined;

  public get window(): Readonly<Window> | undefined {
    return this._window;
  }

  public appendToPage(): Promise<void> {
    this.iframe = document.createElement('iframe');
    this.iframe.name = 'dentolo-iframe';
    this.iframe.className = styles.default;
    this.element = this.iframe;
    
    if (device.ios()) {
      const wrapper = document.createElement('div');
      this.iframe.className = styles.iframe;
      wrapper.className = styles.wrapper;
      wrapper.appendChild(this.iframe);
      this.element = wrapper;
    }
    
    if (this.mode == QuizMode.FULL) this.element.classList.add(styles.full);
    
    return new Promise<void>(resolve => {
      if (/complete|interactive|loaded/.test(document.readyState)) {
        document.body.appendChild(this.element);
        resolve();
      } else {
        document.addEventListener("DOMContentLoaded", () => {
          document.body.appendChild(this.element);
          resolve();
        });
      }
    });
  }
  
  public loadContent(): void {
    this.iframe.src = this.host;
    this._window = this.iframe.contentWindow;
  }

  public show(): void {
    this.element.classList.add(styles.active);
    setTimeout(() => this.element.classList.add(styles.visible), 100);

    setTimeout(() => {
      if (device.ios()) document.body.classList.add(styles.overflowHidden);
      document.documentElement.classList.add(styles.overflowHidden);
    }, 500);
  }

  public hide(): void {
    this.element.classList.remove(styles.visible);
    setTimeout(() => this.element.classList.remove(styles.active), 400);

    if (device.ios()) document.body.classList.remove(styles.overflowHidden);
    document.documentElement.classList.remove(styles.overflowHidden);
  }
}