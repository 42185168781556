import { IsBoolean, IsDefined, IsEnum, IsInt, IsObject, IsOptional, IsString, IsUrl, Max, Min, ValidateIf } from "class-validator";
import { QuizMode } from "../enums/quiz-mode.enum";
import { QuizOptions } from "../interfaces/quiz-options.interface";

export class QuizOptionsDto implements QuizOptions {
  constructor(options: QuizOptions) {
    this.id = options.id;
    this.mode = options.mode;
    this.autoOpen = options.autoOpen;
    this.host = options.host;
    this.openOnScroll = options.openOnScroll || this.openOnScroll;
    this.openOnLeave = options.openOnLeave || this.openOnLeave;
    this.enableGeolocation = options.enableGeolocation || this.enableGeolocation;
  }

  @IsDefined({ message: "1.Необходимо указать параметр $property" })
  @IsString({ message: "2.Параметр $property должен быть строкой" })
  readonly id: string;

  @IsOptional()
  @IsEnum(QuizMode, { message: "Неверное значение для параметра $property" })
  readonly mode?: QuizMode;

  @IsOptional()
  @IsInt({ message: "1.Параметр $property должен быть числом" })
  @Min(0, { message: "2.Параметр $property должен быть равен или больше 0" })
  @Max(600, { message: "3.Время автооткрытия должно быть меньше 10 минут" })
  readonly autoOpen?: number;

  @IsOptional()
  @IsBoolean()
  readonly openOnScroll: boolean = false;

  @IsOptional()
  @IsBoolean()
  readonly openOnLeave: boolean = true;

  @ValidateIf((o: QuizOptionsDto) => !o.host?.includes("localhost"))
  @IsOptional()
  @IsUrl({}, { message: "Параметр $property должен быть url адресом" })
  readonly host?: string;

  @IsOptional()
  @IsObject()
  readonly customParams?: Record<string, any>;

  @IsOptional()
  @IsBoolean()
  readonly enableGeolocation: boolean = false;
}