import { IsOptional, IsString } from "class-validator";
import { FullWidgetViewOptions } from "../interfaces/widget-view-options.interface";

export class FullWidgetViewOptionsDto implements FullWidgetViewOptions {
  constructor(options?: FullWidgetViewOptions) {
    if (options) {
      this.offerText = options.offerText;
      this.buttonText = options.buttonText;
      this.imageSrc = options.imageSrc;
      this.blockStyle = options.blockStyle;
      this.buttonStyle = options.buttonStyle;
      this.offerStyle = options.offerStyle;
      this.buttonHoverStyle = options.buttonHoverStyle;
    }
  }

  @IsString()
  readonly offerText: string;

  @IsString()
  readonly buttonText: string;

  @IsString()
  readonly imageSrc: string;

  @IsOptional()
  readonly blockStyle?: Partial<CSSStyleDeclaration>;

  @IsOptional()
  readonly buttonStyle?: Partial<CSSStyleDeclaration>;

  @IsOptional()
  readonly buttonHoverStyle?: Partial<CSSStyleDeclaration>;

  @IsOptional()
  readonly offerStyle?: Partial<CSSStyleDeclaration>;
}