import { IsBoolean, IsEnum, IsOptional, ValidateNested } from "class-validator";
import { WidgetMode } from "../enums/widget-mode.enum";
import { WidgetOptions } from "../interfaces/widget-options.interface";
import { FullWidgetViewOptionsDto } from "./full-widget-view-options.dto";
import { MiniWidgetViewOptionsDto } from "./mini-widget-view-options.dto";

export class WidgetOptionsDto implements WidgetOptions {
  constructor(private readonly options: WidgetOptions) {
    this.mode = options.mode;
    this.hideOnBottom = options.hideOnBottom;
    this.hideOnOpen = options.hideOnOpen;
    this.showMiniOnCloseFull = options.showMiniOnCloseFull;
  }

  @IsOptional()
  @IsEnum(WidgetMode)
  readonly mode?: WidgetMode = WidgetMode.FULL;

  @IsOptional()
  @IsBoolean()
  readonly hideOnBottom?: boolean = false;

  @IsOptional()
  @IsBoolean()
  readonly hideOnOpen?: boolean = false;

  @IsOptional()
  @IsBoolean()
  readonly showMiniOnCloseFull?: boolean = true;

  @IsOptional()
  @ValidateNested()
  readonly fullWidgetView? = this.options.fullWidgetView && new FullWidgetViewOptionsDto(this.options.fullWidgetView);

  @IsOptional()
  @ValidateNested()
  readonly miniWidgetView? = this.options.fullWidgetView && new MiniWidgetViewOptionsDto(this.options.miniWidgetView);
}