import { IABTest } from "../interfaces/abtest.interface";
import { IAnalyticsPlugin } from "../interfaces/analytics-plugin.interface";

declare global {
  interface Window {
    ym: any;
    dentolo_quiz_ec: any[];
  }
}

export class YaMetrikaCounter implements IAnalyticsPlugin {
  constructor(private readonly metrika_number: number) {}

  private ym: any;

  public init() {
    const SRC = "https://mc.yandex.ru/metrika/tag.js";
    const $this = this;

    this.ym = window.ym || function () {
      $this.ym.a = $this.ym.a || [];
      $this.ym.a.push(arguments);
    };

    this.ym.l = new Date().getTime();
    window.ym = this.ym;

    const isAlreadyExist = document.querySelector(`[src="${SRC}"]`);

    if (!isAlreadyExist) {
      const script = document.createElement("script");
      const firstScript = document.getElementsByTagName("script")[0];
      script.async = true;
      script.src = SRC;
      firstScript?.parentNode?.insertBefore(script, firstScript);
    }

    window.ym(this.metrika_number, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    });
  }

  public setParams(params: Record<string, any>) {
    this.ym(this.metrika_number, "params", params);
  }

  public track(event: string, params?: Record<string, any>) {
    this.ym(this.metrika_number, "reachGoal", event, params);
  }

  public setABTest(abTest: IABTest) {
    this.ym(this.metrika_number, "params", {
      ABTest: {
        [abTest.test_name]: abTest.test_variant
      }
    })
  }
}
