import { QuizEvent } from "../enums/quiz-event.enum";
import { QuizStatus } from "../enums/quiz-status.enum";
import { IIframe } from "../interfaces/iframe.interface";
import { QuizOptions } from "../interfaces/quiz-options.interface";
import { IQuiz } from "../interfaces/quiz.interface";
import { IStorageProvider } from "../interfaces/storage-provider.interface";
import { getQueryParams } from "../utils/get-query-params";
import { EventEmitter } from "./event-emitter";
import { PostMessageProvider } from "../providers/post-message.provider";
import { QuizState } from "./quiz-state";
import { IStoreProvider } from '../interfaces/store-provider.interface';
import { WidgetOptions } from "../interfaces/widget-options.interface";
import { WidgetMode } from '../enums/widget-mode.enum';
import { IWidget } from '../interfaces/widget.interface';
import { QuizMode } from '../enums/quiz-mode.enum';
import { WidgetOptionsDto } from '../dto/widget-options.dto';
import { validateObject } from '../utils/validate-object';
import { ReachGoalData } from '../interfaces/reach-goal-data.interface';
import { OpenTrigger } from '../enums/open-trigger.enum';
import { IHttpProvider } from '../interfaces/http-provider.interface';
import { MiniWidgetViewOptionsDto } from '../dto/mini-widget-view-options.dto';
import { getCookie } from "../utils/get-cookie";
import { IAnalytics } from "../interfaces/analytics.interface";
import { IGeolocationProvider } from "../interfaces/geolocation-provider.interface";
import { IABTest } from "../interfaces/abtest.interface";

export class Quiz implements IQuiz {
  constructor(
    private readonly options: QuizOptions,
    private readonly storeProvider: IStoreProvider<QuizState>,
    private readonly quizEventEmitter: EventEmitter<QuizEvent>,
    private readonly postMessageProvider: PostMessageProvider,
    private readonly storageProvider: IStorageProvider,
    private readonly iframe: IIframe,
    private readonly analytics: IAnalytics,
    private readonly httpProvider: IHttpProvider,
    private readonly geoLocationProvider?: IGeolocationProvider
  ) {}

  private fullWidget: IWidget;
  private miniWidget: IWidget;
  private popupStorage = this.storageProvider.createContainer("autopopup");

  public async init(): Promise<void> {
    const available = await this.checkAvailability();

    if (!available) throw new Error("Quiz is not available");

    this.quizEventEmitter.subscribe<void>(QuizEvent.LOAD, () => this.onLoad());
    this.quizEventEmitter.subscribe<ReachGoalData>(
      QuizEvent.REACH_GOAL,
      (data) => this.analytics.track(data.event, data.params)
    );

    this.quizEventEmitter.subscribe(QuizEvent.SET_PARAMS, params => this.analytics.setParams(params));
    this.quizEventEmitter.subscribe(QuizEvent.SET_AB_TEST, (abTest: IABTest) => this.analytics.setABTest(abTest));

    this.iframe.appendToPage();
    this.iframe.loadContent();

    if (this.options.mode == QuizMode.FULL) {
      this.open(OpenTrigger.QUIZ_MODE_FULL);
      this.addMeta();
    }

    if (this.options.mode == QuizMode.POPUP) {
      if (this.options.autoOpen) this.autoOpen();
      this.quizEventEmitter.subscribe(QuizEvent.CLICK_CROSS, () => this.close());
    }

    if (this.options.openOnScroll && this.popupStorage.isExpired()) {
      window.addEventListener("scroll", () => this.onScroll());
    }

    if (this.options.openOnLeave && this.popupStorage.isExpired()) {
      document.addEventListener("mouseleave", (e) => this.onLeave(e));
    }

    this.quizEventEmitter.emit(QuizEvent.INIT);
  }

  public open(trigger: OpenTrigger = OpenTrigger.OTHER) {
    if (this.storeProvider.getState().status == QuizStatus.OPENED) return;
    this.iframe.show();

    const openAction = () => {
      this.popupStorage.setExpiry(1).save();
      this.postMessageProvider.send({
        event: QuizEvent.OPEN,
        payload: { trigger },
      });
      this.storeProvider.updateState({
        status: QuizStatus.OPENED,
        wasOpened: true,
      });
      this.quizEventEmitter.emit(QuizEvent.OPEN);
    };

    if (this.storeProvider.getState().wasLoaded) return openAction();

    const subscription = this.quizEventEmitter.subscribe(
      QuizEvent.LOAD,
      openAction
    );
    subscription.unsubscribeOnEmit();
  }

  public close() {
    if (this.storeProvider.getState().status == QuizStatus.CLOSED) return;
    this.storeProvider.updateState({
      status: QuizStatus.CLOSED,
      wasClosed: true,
    });
    this.iframe.hide();
  }

  public async addWidget(options: WidgetOptions): Promise<void> {
    validateObject(new WidgetOptionsDto(options));

    const miniWidgetModule = await import("./mini-widget");
    const fullWidgetModule = await import("./full-widget");
    this.fullWidget = new fullWidgetModule.FullWidget(options.fullWidgetView);
    this.miniWidget = new miniWidgetModule.MiniWidget(
      new MiniWidgetViewOptionsDto(options.miniWidgetView)
    );

    if (options.mode == WidgetMode.MINI) {
      this.miniWidget.appendToPage();
      this.miniWidget.onClick(() => this.open(OpenTrigger.WIDGET));
      setTimeout(() => {
        console.log("show mini");
        this.miniWidget.show();
      }, 500);
    } else {
      this.fullWidget.appendToPage();
      this.fullWidget.onClick(() => {
        this.open(OpenTrigger.WIDGET);
        if (options.hideOnOpen) this.fullWidget.close();
      });
      setTimeout(() => this.fullWidget.show(), 500);

      if (options.showMiniOnCloseFull) {
        this.miniWidget.appendToPage();
        this.miniWidget.onClick(() => this.open());
        this.fullWidget.onClose(() =>
          setTimeout(() => this.miniWidget.show(), 500)
        );
      }
    }
  }

  public bind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.subscribe(event, callback);
  }

  public unbind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.unsubscribe(event, callback);
  }

  private autoOpen() {
    setTimeout(() => {
      const { wasOpened, status } = this.storeProvider.getState();
      if (
        !wasOpened &&
        status == QuizStatus.CLOSED &&
        this.popupStorage.isExpired()
      ) {
        this.open(OpenTrigger.AUTO_OPEN);
      }
    }, this.options.autoOpen * 1000);
  }

  private async checkAvailability(): Promise<boolean> {
    const { active } = await this.httpProvider.get<{ active: boolean }>(
      `/quizzes/${this.options.id}/availability`
    );
    return active;
  }

  private async onLoad() {
    const geolocation = await this.geoLocationProvider?.getGeoData();

    this.storeProvider.updateState({ wasLoaded: true });
    this.postMessageProvider.send({
      event: QuizEvent.SET_PLUGIN_DATA,
      payload: {
        quizId: this.options.id,
        mode: this.options.mode,
        extra: {
          ...getQueryParams(),
          ...this.options.customParams,
          domain: document.domain,
          href: window.location.href,
          geolocation: geolocation,
          cookies: {
            _ga: getCookie("_ga"),
            _fbp: getCookie("_fbp"),
            _ym_uid: getCookie("_ym_uid"),
            roistat_visit: getCookie("roistat_visit"),
            roistat_first_visit: getCookie("roistat_first_visit"),
            roistat_visit_cookie_expire: getCookie(
              "roistat_visit_cookie_expire"
            ),
          },
        },
      },
    });
  }

  private onScroll(): void {
    const { pageYOffset, innerHeight } = window;

    if (this.storeProvider.getState().wasOpened) {
      return window.removeEventListener("scroll", this.onScroll);
    }

    if (pageYOffset + innerHeight * 1.1 >= document.body.offsetHeight) {
      this.open(OpenTrigger.SCROLL);
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  private onLeave(event: MouseEvent): void {
    if (this.storeProvider.getState().wasOpened) {
      return document.removeEventListener("mouseleave", this.onLeave);
    }

    if (event.clientY < 0) {
      this.open(OpenTrigger.LEAVE);
      document.removeEventListener("mouseleave", this.onLeave);
    }
  }

  private addMeta() {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
    document.head.appendChild(meta);
  }
}